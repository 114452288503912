<template>
  <div>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="editProfileConfirm" />
    <base-snackbar-notification ref="editProfileSnackbar" />
    <v-card height="100vh" elevation="0">
      <v-card-text>
        <template v-if="userModel.id !== ''">
          <v-form ref="profileForm" @submit.prevent="save()">
            <!-- <v-row justify="center">
              <v-avatar v-if="userModel.profilePicture" size="100">
                <v-img :src="userModel.profilePicture"></v-img>
              </v-avatar>

              <v-avatar v-else size="100">
                <v-img src="https://cdn.vuetifyjs.com/images/john.jpg"></v-img>
              </v-avatar>
            </v-row> -->
            <v-row class="pt-4">
              <v-col md="6"  class="py-1">
                <v-text-field
                  label="First Name"
                  v-model="userModel.firstName"
                  outlined
                  class="text-body-1"
                  dense
                ></v-text-field>
              </v-col>
              <v-col md="6"  class="py-1">
                <v-text-field
                  label="Last Name"
                  v-model="userModel.lastName"
                  outlined
                  class="text-body-1"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12"  class="py-1">
                <mobile-number-input
                    @updateMobileNumberInput="mobileNumberFunc"
                    :allowTestNumbers="true"
                    :initialValue="userModel.telNumber"
                    class="text-body-1"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" class="py-1"><v-text-field
                  label="Email"
                  v-model="userModel.email"
                  outlined
                  class="text-body-1"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="userModel.idNumber">
              <v-col md="6" class="py-1">
                <v-text-field
                  label="ID Number"
                  v-model="userModel.idNumber"
                  outlined
                  class="text-body-1"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col md="6" class="py-1">
                <v-text-field
                  label="Passport"
                  v-model="userModel.passport"
                  outlined
                  class="text-body-1"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="py-1">
                <v-textarea
                  label="Address"
                  outlined
                  counter
                  v-model="userModel.fullAddress"
                  class="text-body-1"
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <v-btn type="submit" color="primary" elevation="0">
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { User } from "@/models/User";
import { gql } from "apollo-boost";
import MobileNumberInput from '@/components/Base/MobileNumberInput.vue'

export default {
  name: "My Profile",
  components: {
    MobileNumberInput
  },
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      userModel: {
        id: "",
        role: "",
        firstName: "",
        lastName: "",
        telNumber: "",
        email: "",
        idNumber: "",
        passport: "",
        fullAddress: "",
        profilePicture: "",
      },
      menu: false,
      date: null,
      search: "",
      dialog3: false,
      totalRecords: 0,
      id: "",
    };
  },
  created() {
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {},
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
            email
            telNumber
            role
          }
        }
      `,
      fetchPolicy: "network-only",
      result() {

        if (this.me.id != "" || null) {
          this.isEdit = true;
          this.id = this.me.id;
        }
      },
    },
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            telNumber
            bankName
            branchCode
            accountType
            accountNumber
            accountHolder
            email
            passport
            idNumber
            role
            fullAddress
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        return {
          id: this.id,
        };
      },
      result(response) {
        if (response.data.user.id != "" || null) {
          this.userModel.id = this.user.id;
          this.userModel.role = this.user.role;
          this.userModel.firstName = this.user.firstName;
          this.userModel.lastName = this.user.lastName;
          this.userModel.telNumber = this.user.telNumber;
          this.userModel.email = this.user.email;
          this.userModel.idNumber = this.user.idNumber;
          this.userModel.fullAddress = this.user.fullAddress;
          this.userModel.passport = this.user.passport;
          this.userModel.bankName = this.user.bankName;
          this.userModel.branchCode = this.user.branchCode;
          this.userModel.accountType = this.user.accountType;
          this.userModel.accountNumber = this.user.accountNumber;
          this.userModel.accountHolder = this.user.accountHolder;
          this.userModel.profileStatus = this.user.profileStatus;
          this.userModel.updatedBy = this.me.id;
          this.userModel.updatedByName = this.user.updatedByName;
          this.userModel.payslipHtml = this.user.payslipHtml;
          this.userModel.contractHtml = this.user.contractHtml;
          this.userModel.netPay = this.user.netPay;
          this.userModel.uif = this.user.uif;
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }
      },
    },
  },
  // apollo: {
  //   me: {
  //     query: gql`
  //       query {
  //         me {
  //           id
  //           firstName
  //           lastName
  //           profilePicture
  //         }
  //       }
  //     `,
  //     fetchPolicy: "no-cache",
  //     result() {
  //       console.log("this.me", this.me);
  //       if (this.me.id != "" || null) {
  //         this.isEdit = true;
  //         this.id = this.me.id;
  //       }
  //     },
  //   },
  //   user: {
  //     query: gql`
  //       query User($id: ID!) {
  //         user(id: $id) {
  //           id
  //           firstName
  //           lastName
  //           telNumber
  //           email
  //           passport
  //           idNumber
  //           fullAddress
  //           profilePicture
  //         }
  //       }
  //     `,
  //     fetchPolicy: "network-only",
  //     skip() {
  //       return !this.isEdit;
  //     },
  //     variables() {
  //       console.log();
  //       return {
  //         id: this.id,
  //       };
  //     },
  //     result(response) {
  //       if (response.data.user.id != "" || null) {
  //         this.userModel.id = this.user.id;
  //         this.userModel.role = this.user.role;
  //         this.userModel.firstName = this.user.firstName;
  //         this.userModel.lastName = this.user.lastName;
  //         this.userModel.telNumber = this.user.telNumber;
  //         this.userModel.email = this.user.email;
  //         this.userModel.idNumber = this.user.idNumber;
  //         this.userModel.fullAddress = this.user.fullAddress;
  //         this.userModel.passport = this.user.passport;
  //         this.userModel.profilePicture = this.user.profilePicture;
  //         this.isEdit = true;
  //       } else {
  //         this.isEdit = false;
  //       }
  //     },
  //   },
  // },
  methods: {
    mobileNumberFunc(value) {
      this.userModel.telNumber = value
    },
    async resetPassword() {
      const self = this;
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.PasswordRestForm.validate()) {
        try {
          const resetPasswordLink = await this.$apollo.mutate({
            mutation: gql`
              mutation ($email: String!) {
                getResetPasswordLink(email: $email)
              }
            `,
            variables: {
              email: this.me.email,
            },
          });
          if (resetPasswordLink) {
            self.successMessage = `Password Reset Link sent to ${this.email}`;
          }
          self.isLoading = false;
        } catch (error) {
          if (error.response) {
            self.errorMessage = `${error.response.data}`;
          } else
            self.errorMessage = `Password reset link not sent: ${error.message}`;
          self.isLoading = false;
        }
      } else {
        self.errorMessage =
          "Password reset link not sent: All required fields must be completed.";
        self.isLoading = false;
      }
    },
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.profileForm.validate()) {
        this.$refs.editProfileConfirm.open("Warning", "There was a problem while saving your changes. Please check your information and try again.", "warning")
        self.isLoading = false;
        return;
      }

      if (self.isEdit) {
        await this.updateUser().then(() => {
          this.$refs.editProfileSnackbar.open("Your profile was saved.", 'success')
        })
        .catch((e) => {
          this.$refs.editProfileConfirm.open("Error", "There was a problem while saving your changes. Please check your information and try again.", "error")
        });
      }
      this.isLoading = false;
    },
    async updateUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.userModel.id,
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              gender: self.userModel.gender,
              telNumber: self.userModel.telNumber,
              email: self.userModel.email,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              bankName: self.userModel.bankName,
              branchCode: self.userModel.branchCode,
              accountHolder: self.userModel.accountHolder,
              accountNumber: self.userModel.accountNumber,
              accountType: self.userModel.accountType,
              role: "employer",
              updatedBy: self.me.id,
              updatedByName: self.userModel.updatedByName,
              profileStatus: self.userModel.profileStatus,
              checkIn: self.userModel.checkIn,
              payslipHtml: self.userModel.payslipHtml,
              contractHtml: self.userModel.contractHtml,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when updating your data!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
